import React, { useEffect, useState } from "react"
import Container from "../components/common/Container"
import SEO from "../components/common/SEO"
import IconLogo from "../assets/images/logo.inline.svg"
import IconChevronRight from "../assets/images/icon-chevron-right.inline.svg"
import IconInfo from "../assets/images/icon-info.inline.svg"
import IconX from "../assets/images/icon-x.inline.svg"
import IconCheckCircled from "../assets/images/icon-check-circled.inline.svg"
import IconPrivate from "../assets/images/icon-private.inline.svg"
import GlobalStyles from "../components/common/GlobalStyles"
import imagePayment from "../assets/images/payment.webp"
import { Field, Formik } from "formik"
import styled from "styled-components"
import Button from "../components/common/Button"
import Cookies from "js-cookie"
import { v4 as uuidv4 } from "uuid"
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import Radio from "../components/common/Radio"
import axios from "axios"
import * as Yup from "yup"
import { format, fromUnixTime } from "date-fns"
import { Link } from "gatsby"
import { DEVICES, MEDIA_QUERIES } from "../config"
import { LIGHT_THEME } from "../theme"
import FormikInput from "../components/common/FormikInput"
import Dropdown from "../components/common/Dropdown"
import Spinner from "../components/common/Spinner"
import StyledInput from "../components/common/StyledInput"

const PHONE_REG_EXP = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const PRICE = 150
const getPriceInCurrency = price => `$${price}`

const BASE_URL = "https://us-central1-pnoe-diet.cloudfunctions.net"

let stripePromise = loadStripe("pk_live_LI0pry6uPNCsrBoQsCQHtivy")
let PAYMENT_API_URL = `${BASE_URL}/blogPayment`
let CREATE_CUSTOMER_API = `${BASE_URL}/createStripeCustomer`
let COUPON_API = `${BASE_URL}/fetchCouponCode`

if (
  process.env.NODE_ENV === "development" ||
  process.env.GATSBY_DEPLOY_ENV === "branch"
) {
  stripePromise = loadStripe("pk_test_OKfE3m7B9ZcUPZcFobltYhKf")
  PAYMENT_API_URL = `${BASE_URL}/blogPaymentTest`
  CREATE_CUSTOMER_API = `${BASE_URL}/createStripeCustomerTest`
  COUPON_API = `${BASE_URL}/fetchCouponCodeTest`
}

const Bullet = styled.div`
  border-radius: 100%;
  min-width: 8px;
  min-height: 8px;
  margin: 8px;
  background: #438ffa;
`

const StyledButton = styled(Button)`
  min-width: 140px;
  min-height: 45px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;

  :disabled {
    opacity: 0.5;
  }
`

function Status({ icon, name, children }) {
  return (
    <Container
      light
      compact
      css={`
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 32px;
      `}
    >
      <Link to="/">
        <IconLogo
          css={`
            height: 30px;
            margin: 72px 0;
          `}
        />
      </Link>
      <div
        css={`
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 64px;
          border-radius: 8px;
          border: solid 2px #d6d5d5;
          width: 100%;

          & > * {
            margin-bottom: 16px;
          }
        `}
      >
        {icon}
        <div
          css={`
            font-size: 24px;
            font-weight: bold;
            line-height: 1.33;
          `}
        >
          {name}
        </div>
        {children}
      </div>
    </Container>
  )
}

const FormControl = styled.div`
  margin-bottom: 24px;

  label {
    display: block;
    margin-bottom: 16px;
    opacity: 0.7;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
  }
`

const TwoColumns = styled(FormControl)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px 32px;

  @media (max-width: ${DEVICES.tablet}px) {
    display: flex;
    flex-direction: column;
    gap: 0;

    & > *:not(:last-child) {
      margin-bottom: 24px;
    }
  }
`

const BoxTitle = styled.h2`
  margin: 0;
  margin-bottom: 24px;
`

const BoxSubtitle = styled.div`
  opacity: 0.7;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 24px;
`

function Box({ number = 0, title, subtitle, children }) {
  return (
    <div
      css={`
        border-radius: 8px;
        border: solid 2px #d6d5d5;
        padding: 40px;
        display: flex;
        margin-bottom: 32px;

        @media (max-width: ${DEVICES.tablet}px) {
          flex-direction: column;
          padding: 20px;
        }
      `}
    >
      <div
        css={`
          margin-right: 40px;
          padding: 2px 13px;
          border-radius: 8px;
          border: solid 2px #d6d5d5;
          align-self: flex-start;
          font-size: 16px;
          font-weight: bold;
          line-height: 1.5;
          text-align: center;
          color: #0095ff;

          @media (max-width: ${DEVICES.tablet}px) {
            display: none;
          }
        `}
      >
        {number}
      </div>
      <div
        css={`
          flex: 1;
        `}
      >
        <BoxTitle>{title}</BoxTitle>
        {subtitle && <BoxSubtitle>{subtitle}</BoxSubtitle>}
        {children}
      </div>
    </div>
  )
}

function Form({ onFinish, location, idempotencyKey }) {
  const stripe = useStripe()
  const elements = useElements()
  const [checking, setChecking] = useState(false)
  const [message, setMessage] = useState({
    color: "",
    text: "",
  })
  const [promo, setPromo] = useState()
  const [promoCode, setPromoCode] = useState("")

  async function onRedeem() {
    if (promo) {
      setPromo()
      setPromoCode("")
      setMessage({
        color: "",
        text: "",
      })
      return
    }

    setChecking(true)
    const {
      data: { promotionCode },
    } = await axios.post(COUPON_API, {
      promoCode,
      couponPrefix: process.env.GATSBY_COUPON_PREFIX,
    })
    setChecking(false)

    if (!promotionCode) {
      setMessage({
        color: "var(--error)",
        text: "Your code was not approved. Please enter a valid coupon code.",
      })
    } else if (
      promotionCode &&
      promotionCode.coupon.valid &&
      promotionCode.active
    ) {
      setMessage({
        color: "#00A68A",
        text: "Your coupon code has been approved, thank you for your order!",
      })
      setPromo(promotionCode)
    } else if (
      promotionCode &&
      promotionCode.coupon.valid &&
      !promotionCode.active
    ) {
      setMessage({
        color: "var(--error)",
        text:
          "This coupon has been used already. Please enter a valid code to proceed.",
      })
    }
  }

  function calculatePrice(quantity) {
    let price = PRICE * quantity

    if (promo) {
      price = price - (price * promo.coupon.percent_off) / 100
    }

    return price
  }

  return (
    <>
      <Container
        as="header"
        compact
        light
        css={`
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          align-items: center;
          padding: 72px 0;

          svg.auto-svg {
            height: 30px;
          }

          @media (max-width: ${DEVICES.tablet}px) {
            display: flex;
            flex-direction: column-reverse;
            padding: 36px 0;

            & > *:not(:last-child) {
              margin-top: 16px;
            }
          }
        `}
      >
        <div
          css={`
            display: flex;
            align-items: center;
          `}
        >
          <span
            css={`
              opacity: 0.55;
              font-size: 16px;
              font-weight: bold;
              line-height: 1.5;
              margin-right: 15px;
            `}
          >
            Information
          </span>
          <IconChevronRight
            css={`
              margin-right: 15px;
              height: 20px;
            `}
          />

          <span
            css={`
              font-size: 16px;
              font-weight: bold;
              line-height: 1.5;
            `}
          >
            Payment
          </span>
        </div>
        <div
          css={`
            text-align: center;
          `}
        >
          <Link to="/">
            <IconLogo />
          </Link>
        </div>
      </Container>
      <Formik
        initialValues={{
          agreeTerms: false,
          quantity: 1,
          isShippingDifferent: false,
          billing: {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            company: "",
            country: "",
            addressOne: "",
            addressTwo: "",
            postalCode: "",
            city: "",
          },
          shipping: {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            company: "",
            country: "",
            addressOne: "",
            addressTwo: "",
            postalCode: "",
            city: "",
          },
          creditCard: {
            name: "",
          },
        }}
        validationSchema={Yup.object({
          agreeTerms: Yup.bool().oneOf([true], "Field must be checked"),
          quantity: Yup.number().required(),
          billing: Yup.object({
            firstName: Yup.string().required(),
            lastName: Yup.string().required(),
            email: Yup.string().email().required(),
            phone: Yup.string()
              .matches(PHONE_REG_EXP, "Phone number is not valid")
              .required(),
            addressOne: Yup.string().required(),
            postalCode: Yup.string().required(),
            city: Yup.string().required(),
          }),
          shipping: Yup.object().when("isShippingDifferent", {
            is: true,
            then: Yup.object({
              firstName: Yup.string().required(),
              addressOne: Yup.string().required(),
              postalCode: Yup.string().required(),
              city: Yup.string().required(),
              phone: Yup.string()
                .matches(PHONE_REG_EXP, "Phone number is not valid")
                .required(),
            }),
          }),
          creditCard: Yup.object({
            name: Yup.string().required(),
          }),
        })}
        onSubmit={async (values, actions) => {
          if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return
          }

          const metadata = {
            ...values,
            from: location.href,
            promoId: promo?.id,
            couponId: promo?.coupon.id,
            promoCode: promo ? promoCode : undefined,
          }

          const cardNumber = elements.getElement(CardNumberElement)
          const {
            data: { client_secret },
          } = await axios.post(PAYMENT_API_URL, {
            metadata,
            idempotencyKey,
          })

          const result = await stripe.confirmCardPayment(client_secret, {
            payment_method: {
              card: cardNumber,
              billing_details: {
                name: `${values.billing.firstName} ${values.billing.lastName}`,
                email: values.billing.email,
              },
            },
          })

          if (result.error) {
            onFinish("failure", result)
          } else if (result.paymentIntent.status === "succeeded") {
            await axios.post(CREATE_CUSTOMER_API, {
              metadata,
              payment_method: result.paymentIntent.payment_method,
              idempotencyKey,
            })
            await submitHubspotForm({ values })
            onFinish("success", result)
          }
        }}
      >
        {({
          handleSubmit,
          handleChange,
          isSubmitting,
          values,
          setFieldValue,
          errors,
        }) => (
          <Container light compact as="form" onSubmit={handleSubmit}>
            <Box number={1} title="Overview">
              <img
                css={`
                  margin-bottom: 32px;
                  width: 100%;
                `}
                src={imagePayment}
                alt="Payment"
              />
              <div
                css={`
                  display: flex;
                  margin-bottom: 40px;
                `}
              >
                <div
                  css={`
                    width: 24px;
                    height: 24px;
                    margin-right: 16px;
                    opacity: 0.4;
                    align-self: flex-start;
                  `}
                >
                  <IconInfo />
                </div>
                <div
                  css={`
                    div {
                      display: flex;
                      align-items: flex-start;
                    }

                    p {
                      opacity: 0.7;
                      font-size: 16px;
                      line-height: 1.5;
                      margin: 0;
                      margin-left: 10px;

                      small {
                        opacity: 0.55;
                        font-size: 12px;
                        line-height: 2;
                        font-style: italic;
                      }
                    }

                    div:not(:last-child) {
                      margin-bottom: 16px;
                    }
                  `}
                >
                  <h3
                    css={`
                      margin: 0;
                      margin-bottom: 16px;
                      line-height: 1.5;
                      font-size: 16px;
                      font-weight: bold;
                    `}
                  >
                    Pricing
                  </h3>
                  <div>
                    <Bullet />
                    <p>
                      <b>{getPriceInCurrency(PRICE)}</b> to keep the PNOĒ device
                      for 48h and conduct a single resting and exercise
                      assessment. Time starts when you receive it. <br />
                      <small>$50 per additional 24h thereafter.</small>
                    </p>
                  </div>
                  <div>
                    <Bullet />
                    <p>
                      3 month FREE access to the PNOĒ Precision app. <br />
                      <small>$10/mo thereafter.</small>
                    </p>
                  </div>
                </div>
              </div>
              {/* <div
                css={`
                  display: flex;
                  margin-bottom: 32px;
                `}
              >
                <div
                  css={`
                    width: 24px;
                    height: 24px;
                    margin-right: 16px;
                    opacity: 0.4;
                    align-self: flex-start;
                  `}
                >
                  <IconExclamationCircle />
                </div>
                <h3
                  css={`
                    margin: 0;
                    line-height: 1.5;
                    font-size: 16px;
                    font-weight: bold;
                  `}
                >
                  The PNOĒ kit is fully insured for any physical damage
                </h3>
              </div> */}
            </Box>
            <Box number={2} title="Number of tests">
              <div
                css={`
                  display: flex;
                  align-items: center;

                  ${MEDIA_QUERIES.belowTablet} {
                    flex-direction: column;
                    align-items: flex-start;
                  }
                `}
              >
                <Dropdown
                  selected={{ value: values.quantity, label: values.quantity }}
                  options={Array.from({ length: 10 }).map((x, index) => ({
                    value: index + 1,
                    label: index + 1,
                  }))}
                  onChange={selected =>
                    setFieldValue("quantity", selected.value)
                  }
                />
                <div
                  css={`
                    display: flex;
                    align-items: center;
                    margin-left: 24px;

                    ${MEDIA_QUERIES.belowTablet} {
                      margin-top: 24px;
                      margin-left: 0;
                    }
                  `}
                >
                  <label>Total Amount</label>
                  <div
                    css={`
                      margin-left: 24px;
                      font-size: 24px;
                      color: #0095ff;
                    `}
                  >
                    {getPriceInCurrency(calculatePrice(values.quantity))}
                  </div>
                </div>
              </div>
              <div
                css={`
                  margin-top: 24px;
                  display: flex;
                  align-items: center;
                  gap: 24px;
                `}
              >
                <StyledInput
                  value={promoCode}
                  disabled={checking || promo}
                  onChange={e => {
                    setPromoCode(e.target.value)
                    setMessage({
                      color: "",
                      text: "",
                    })
                  }}
                  onKeyDown={keyEvent => {
                    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                      keyEvent.preventDefault()
                    }
                  }}
                  placeholder="Coupon Code"
                  css={`
                    width: auto;
                    text-transform: uppercase;
                    min-width: 300px;
                    @media (max-width: ${DEVICES.mobile}px) {
                      min-width: 280px;
                    }
                    border-color: ${message.text
                      ? message.color
                      : "transparent"};
                  `}
                />
                <div>
                  <StyledButton
                    disabled={checking || !promoCode}
                    type="button"
                    onClick={onRedeem}
                  >
                    {checking ? (
                      <Spinner size={2} />
                    ) : promo ? (
                      "Clear"
                    ) : (
                      "Redeem"
                    )}
                  </StyledButton>
                </div>
              </div>
              {message.text && (
                <div
                  css={`
                    margin-top: 24px;
                    color: ${message.color};
                  `}
                >
                  {message.text}
                </div>
              )}
            </Box>
            <Box number={3} title="Billing Info" subtitle="Billing Address">
              <div>
                <TwoColumns>
                  <Field
                    component={FormikInput}
                    name="billing.firstName"
                    placeholder="First Name"
                  />
                  <Field
                    component={FormikInput}
                    name="billing.lastName"
                    placeholder="Last Name"
                  />
                  <Field
                    component={FormikInput}
                    name="billing.email"
                    placeholder="Email"
                  />
                  <Field
                    component={FormikInput}
                    name="billing.phone"
                    placeholder="Phone"
                  />
                </TwoColumns>
                <FormControl>
                  <Field
                    component={FormikInput}
                    name="billing.company"
                    placeholder="Company (optional)"
                  />
                </FormControl>

                <FormControl>
                  <Dropdown
                    selected={
                      values.billing.country && {
                        value: values.billing.country,
                        label: values.billing.country,
                      }
                    }
                    options={[
                      { value: "Greece", label: "Greece" },
                      { value: "USA", label: "USA" },
                    ]}
                    onChange={selected =>
                      setFieldValue("billing.country", selected.value)
                    }
                    placeholder="Select country"
                  />
                </FormControl>
                <FormControl>
                  <Field
                    component={FormikInput}
                    name="billing.addressOne"
                    placeholder="Address"
                  />
                </FormControl>
                <FormControl>
                  <Field
                    component={FormikInput}
                    name="billing.addressTwo"
                    placeholder="Apartment, suite, etc. (optional)"
                  />
                </FormControl>
                <TwoColumns>
                  <Field
                    component={FormikInput}
                    name="billing.postalCode"
                    placeholder="Postal Code"
                  />
                  <Field
                    component={FormikInput}
                    name="billing.city"
                    placeholder="City"
                  />
                </TwoColumns>
                <Radio
                  checked={values.isShippingDifferent}
                  error={errors.isShippingDifferent}
                  onChange={e =>
                    setFieldValue("isShippingDifferent", e.target.checked)
                  }
                >
                  Ship to a different address
                </Radio>
              </div>

              {values.isShippingDifferent && (
                <div
                  css={`
                    margin-top: 72px;
                  `}
                >
                  <BoxTitle>Shipping Info</BoxTitle>
                  <BoxSubtitle>Shipping Address</BoxSubtitle>
                  <FormControl>
                    <Field
                      component={FormikInput}
                      name="shipping.addressOne"
                      placeholder="Address"
                    />
                  </FormControl>
                  <FormControl>
                    <Field
                      component={FormikInput}
                      name="shipping.addressTwo"
                      placeholder="Apartment, suite, etc. (optional)"
                    />
                  </FormControl>
                  <TwoColumns>
                    <Field
                      component={FormikInput}
                      name="shipping.postalCode"
                      placeholder="Postal Code"
                    />
                    <Field
                      component={FormikInput}
                      name="shipping.city"
                      placeholder="City"
                    />
                    <Field
                      component={FormikInput}
                      name="shipping.firstName"
                      placeholder="Recipient's Name"
                    />
                    <Field
                      component={FormikInput}
                      name="shipping.phone"
                      placeholder="Phone"
                    />
                  </TwoColumns>
                </div>
              )}
            </Box>
            <Box number={4} title="Credit Card Info">
              <FormControl
                css={`
                  max-width: 498px;
                `}
              >
                <label>Name on Card</label>
                <Field
                  component={FormikInput}
                  name="creditCard.name"
                  placeholder="Name on card"
                />
              </FormControl>
              <FormControl
                css={`
                  max-width: 498px;
                `}
              >
                <label>Card Number</label>
                <CardNumberElement
                  css={`
                    padding: 16px 24px;
                    border: none;
                    border-radius: 8px;
                    box-shadow: inset -3px -3px 5px 0 #ffffff,
                      inset 3px 3px 5px 0 rgba(25, 25, 25, 0.15);
                    background-color: #ebeaea;
                    outline-color: #0095ff;
                    width: 100%;
                  `}
                />
              </FormControl>
              <div
                css={`
                  display: flex;
                  margin-bottom: 32px;

                  @media (max-width: ${DEVICES.tablet}px) {
                    flex-direction: column;

                    & > *:not(:last-child) {
                      margin-bottom: 16px;
                    }
                  }
                `}
              >
                <FormControl
                  css={`
                    margin-right: 24px;
                    margin-bottom: 0;
                  `}
                >
                  <label>Expiration Date</label>
                  <CardExpiryElement
                    css={`
                      padding: 16px 24px;
                      border: none;
                      border-radius: 8px;
                      box-shadow: inset -3px -3px 5px 0 #ffffff,
                        inset 3px 3px 5px 0 rgba(25, 25, 25, 0.15);
                      background-color: #ebeaea;
                      outline-color: #0095ff;
                      width: 184px;
                    `}
                  />
                </FormControl>
                <FormControl
                  css={`
                    margin-bottom: 0;
                  `}
                >
                  <label>CVV</label>
                  <CardCvcElement
                    css={`
                      padding: 16px 24px;
                      border: none;
                      border-radius: 8px;
                      box-shadow: inset -3px -3px 5px 0 #ffffff,
                        inset 3px 3px 5px 0 rgba(25, 25, 25, 0.15);
                      background-color: #ebeaea;
                      outline-color: #0095ff;
                      width: 184px;
                    `}
                  />
                </FormControl>
              </div>
              <Radio
                css={`
                  margin-bottom: 32px;
                `}
                checked={values.agreeTerms}
                error={errors.agreeTerms}
                onChange={e => setFieldValue("agreeTerms", e.target.checked)}
              >
                Agree to{" "}
                <a
                  href="https://www.mypnoe.com/privacy-policy/"
                  rel="noreferrer"
                  target="_blank"
                  css={`
                    color: currentColor;
                  `}
                >
                  terms & conditions
                </a>
              </Radio>
              <StyledButton
                disabled={!elements || isSubmitting}
                type="submit"
                css={`
                  svg.auto-svg {
                    margin-left: 24px;
                    fill: currentColor;
                  }
                `}
              >
                {isSubmitting ? (
                  <Spinner size={2} />
                ) : (
                  <>
                    Submit <IconPrivate />
                  </>
                )}
              </StyledButton>
            </Box>
          </Container>
        )}
      </Formik>
    </>
  )
}

function Payment({ location }) {
  const [status, setStatus] = useState(null)
  const [result, setResult] = useState(null)
  const [idempotencyKey, setIdempotencyKey] = useState()

  useEffect(() => {
    if (!idempotencyKey) {
      setIdempotencyKey(uuidv4())
    }
  }, [idempotencyKey])

  return (
    <Elements stripe={stripePromise}>
      <div
        css={`
          min-height: 100vh;
        `}
      >
        <SEO title="Payment" />
        <GlobalStyles theme={LIGHT_THEME} />
        {!status && (
          <Form
            idempotencyKey={idempotencyKey}
            location={location}
            onFinish={(status, result) => {
              setStatus(status)
              setResult(result)
            }}
          />
        )}
        {status === "success" && result && (
          <Status name="Payment Successful" icon={<IconCheckCircled />}>
            <hr
              css={`
                width: 70%;
                border: solid 1px #d6d5d5;
                margin-bottom: 32px;
              `}
            />
            <div
              css={`
                display: flex;
                margin-bottom: 24px;
              `}
            >
              <div
                css={`
                  margin-right: 46px;
                `}
              >
                <span
                  css={`
                    margin-right: 10px;
                    font-size: 18px;
                    font-weight: bold;
                    line-height: 1.33;
                  `}
                >
                  Date
                </span>
                <span
                  css={`
                    opacity: 0.7;
                    font-size: 16px;
                    line-height: 1.5;
                  `}
                >
                  {format(
                    fromUnixTime(result.paymentIntent.created),
                    "dd MMM yyyy"
                  )}
                </span>
              </div>
              <div>
                <span
                  css={`
                    margin-right: 10px;
                    font-size: 18px;
                    font-weight: bold;
                    line-height: 1.33;
                  `}
                >
                  Time
                </span>
                <span
                  css={`
                    opacity: 0.7;
                    font-size: 16px;
                    line-height: 1.5;
                  `}
                >
                  {format(fromUnixTime(result.paymentIntent.created), "p")}
                </span>
              </div>
            </div>
            <h3
              css={`
                margin-bottom: 8px;
              `}
            >
              Total Paid Amount
            </h3>
            <h2
              css={`
                margin: 0 0 48px 0;
              `}
            >
              {getPriceInCurrency(result.paymentIntent.amount / 100)}
            </h2>
            <Button
              onClick={() => {
                setStatus(null)
                setResult(null)
                setIdempotencyKey(uuidv4())
              }}
            >
              Done
            </Button>
          </Status>
        )}
        {status === "failure" && result && (
          <Status name="Payment Failure" icon={<IconX />}>
            <div
              css={`
                opacity: 0.7;
                font-size: 16px;
                line-height: 1.5;
                margin-bottom: 48px;
              `}
            >
              {result.error.message}
            </div>
            <Button
              onClick={() => {
                setStatus(null)
                setResult(null)
              }}
            >
              Try Again
            </Button>
          </Status>
        )}
      </div>
    </Elements>
  )
}

async function submitHubspotForm({ values }) {
  const isBrowser = typeof window !== "undefined"
  const hutk = isBrowser ? Cookies.get("hubspotutk") : null
  const pageUri = isBrowser ? window.location.href : null
  const pageName = isBrowser ? document.title : null
  const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/5241029/1cc70683-4729-46a3-9b2f-9985240e0c77`

  try {
    await axios.post(postUrl, {
      submittedAt: Date.now(),
      fields: [
        {
          name: "email",
          value: values.billing.email,
        },
        {
          name: "nickname",
          value: values.billing.firstName,
        },
        {
          name: "firstname",
          value: values.shipping.firstName || values.billing.firstName,
        },
        {
          name: "lastname",
          value: values.shipping.lastName || values.billing.lastName,
        },
        {
          name: "phone",
          value: values.shipping.phone || values.billing.phone,
        },
        {
          name: "address",
          value: values.shipping.addressOne || values.billing.addressOne,
        },
        {
          name: "company",
          value: values.shipping.company || values.billing.company,
        },
        {
          name: "city",
          value: values.shipping.city || values.billing.city,
        },
        {
          name: "postal_code",
          value: values.shipping.postalCode || values.billing.postalCode,
        },
      ],
      context: {
        hutk,
        pageUri,
        pageName,
      },
    })
  } catch (error) {
    console.error(error)
  }
}

export default Payment
