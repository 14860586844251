import React from "react"
import { keyframes } from "styled-components"

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export default function Spinner({ size = 8, color = "#0095ff" }) {
  return (
    <div
      css={`
        display: inline-block;
        position: relative;
        width: ${size * 10}px;
        height: ${size * 10}px;

        > div {
          box-sizing: border-box;
          display: block;
          position: absolute;
          width: ${size * 8}px;
          height: ${size * 8}px;
          margin: ${size}px;
          border: ${size}px solid #fff;
          border-radius: 50%;
          animation: ${spin} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          border-color: ${color} transparent transparent transparent;
        }
        > div:nth-child(1) {
          animation-delay: -0.45s;
        }
        > div:nth-child(2) {
          animation-delay: -0.3s;
        }
        > div:nth-child(3) {
          animation-delay: -0.15s;
        }
      `}
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}
