import React from "react"
import Select from "react-select"
import { DEVICES } from "../../config"

export default function Dropdown({ selected, onChange, options }) {
  return (
    <Select
      css={`
        min-width: 300px;
        @media (max-width: ${DEVICES.mobile}px) {
          min-width: 280px;
        }
      `}
      styles={{
        valueContainer: styles => ({
          ...styles,
          padding: "16px 0 16px 24px",
        }),
        control: styles => ({
          ...styles,
          boxShadow:
            "inset -3px -3px 5px 0 #ffffff, inset 3px 3px 5px 0 rgba(25, 25, 25, 0.15)",
          backgroundColor: "var(--main)",
          border: "none",
          borderRadius: 8,
          ":hover": {
            ...styles[":hover"],
            border: "none",
          },
        }),
        menu: styles => ({
          ...styles,
          backgroundColor: "var(--main)",
        }),
        option: styles => ({
          ...styles,
          backgroundColor: "var(--main)",
          color: "var(--text)",
          ":hover": {
            ...styles[":hover"],
            backgroundColor: "var(--text)",
            color: "var(--main)",
          },
        }),
        singleValue: styles => ({
          ...styles,
          color: "var(--text)",
        }),
        indicatorSeparator: styles => ({
          ...styles,
          display: "none",
        }),
        indicatorsContainer: styles => ({
          ...styles,
          paddingRight: 16,
        }),
      }}
      value={selected}
      onChange={onChange}
      options={options}
    />
  )
}
