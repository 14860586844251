import React from "react"

function Radio({ children, onChange, checked, error, ...props }) {
  return (
    <label
      className="checkbox"
      css={`
        display: grid;
        grid-template-columns: min-content auto;
        gap: 20px;
        color: var(--text);
        outline: none;
        cursor: pointer;
      `}
      {...props}
    >
      <span
        className="checkbox__input"
        css={`
          display: grid;
          grid-template-areas: "checkbox";

          input:checked + .checkbox__control .radio-dot {
            transform: scale(1);
          }

          > * {
            grid-area: checkbox;
          }
        `}
      >
        <input
          type="checkbox"
          name="checked"
          checked={checked}
          onChange={onChange}
          css={`
            opacity: 0;
            width: 1em;
            height: 1em;
          `}
        />
        <span
          className="checkbox__control"
          css={`
            --depth: 4px;

            display: inline-grid;
            width: 24px;
            height: 24px;
            border: 2px solid transparent;
            border-color: ${error ? "var(--error)" : "transparent"};
            transition: border-color 250ms ease;
            border-radius: 100%;

            box-shadow: calc(var(--depth) * -1) calc(var(--depth) * -1)
                calc(var(--depth) * 2) 0 #fff,
              var(--depth) var(--depth) calc(var(--depth) * 2) 0 #d6d6d6,
              inset calc(var(--depth) * -1) calc(var(--depth) * -1)
                calc(var(--depth) * 2) 0 #fff,
              inset var(--depth) var(--depth) calc(var(--depth) * 2) 0 #d6d6d6;

            display: flex;
            align-items: center;

            .radio-dot {
              transition: transform 0.1s ease-in 25ms;
              transform: scale(0);
              padding: 5px;
              border-radius: 100%;
              background-color: #0095ff;
              box-shadow: 0 0 8px 0 #0095ff;
              margin: 0 auto;
            }
          `}
        >
          <div className="radio-dot"></div>
        </span>
      </span>
      <span
        css={`
          opacity: ${error ? 1 : 0.7};
          font-size: 16px;
          font-weight: 500;
          line-height: 1.5;
          color: ${error ? "var(--error)" : "inherit"};
          transition: color 250ms ease;
        `}
        className="radio__label"
      >
        {children}
      </span>
    </label>
  )
}

export default Radio
